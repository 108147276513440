import React, { useState, useEffect, useContext } from "react";
import NavBar from "../../Layouts/NavBar";
import config from "../../Services/config.js";
import Instance from "../../Services/Instance";
import { Link } from "react-router-dom";
import { DataContext } from "../../Services/DataContext";
import "../../Assets/Styles/Subscriptions.css";
import Footer from "../../Layouts/Footer";
import information from "../../Assets/Imgs/icons/attention.svg";
import download from "../../Assets/Imgs/icons/loading2.gif";
import arrowRight from "../../Assets/Imgs/icons/arrow-right-circle-line-black.png";
import iconSuccess from "../../Assets/Imgs/icons/validate-icon.png";
import iconDetails from "../../Assets/Imgs/icons/price-tag-3-fill.svg";
import whatsAppIcon from "../../Assets/Imgs/icons/whatsapp-icon.svg";
import ReqSupport from "../../Assets/Imgs/icons/req-support.svg";
import howToUse from "../../Assets/Imgs/icons/howToUse.svg";
import cartIcon from "../../Assets/Imgs/icons/validate-cart-icon.svg";
import telegramIcon from "../../Assets/Imgs/icons/telegram-svgrepo-com.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCopy } from "@fortawesome/free-solid-svg-icons";

function ValidateSubscription() {
  const { clearItem } = useContext(DataContext);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState([]);
  const [valueFromURL, setValueFromURL] = useState("");
  const [subscriptionValidate, setSubscriptionValidate] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [viewOrderInformation, setViewOrderInformation] = useState(false);
  const [allInfos, setAllInfos] = useState([]);
  const [showPopups, setShowPopups] = useState([]); // Utilisez un tableau pour les popups
  const [showTerme, setShowTerme] = useState(false)
  const [showReqSupport, setShowReqSupport] = useState(false)


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get("value");
    setValueFromURL(value || "");
  }, []);

  const _idOrder = valueFromURL;
  const smartTvPrice = localStorage.getItem("smartTvPrice");
  if (smartTvPrice) {
    localStorage.removeItem("smartTvPrice");
    localStorage.removeItem("netflixType");
  }

  useEffect(() => {
    if (_idOrder) {
      fetchDataNewOrder();
    }
  }, [_idOrder]);

  const fetchDataNewOrder = async () => {
    try {
      const result = await Instance.get(
        `${config.api_url}/show-all-subscriptions/${_idOrder}`
      );
      setOrder(result.data.subscription);
      if (result.data.subscription[0].subscriptionStatus === "Validate") {
        setSubscriptionValidate(true);
      } else {
        ReloadComponent();
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des abonnements :", error);
      setError(error);
    }
  };

  const handleClose = () => {
    clearItem();
  };

  function extraireDateHeure(dateISO) {
    const date = new Date(dateISO);
    return date.toLocaleString("fr-FR", {
      timeZone: "UTC",
      dateStyle: "short",
      timeStyle: "short",
    });
  }

  const OrderInfos = () => {
    setViewOrderInformation(true);
  };

  useEffect(() => {
    const autoShowOrderInfo = setTimeout(() => {
      OrderInfos();
    }, 5000);
    return () => clearTimeout(autoShowOrderInfo);
  }, []);

  const ReloadComponent = () => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          window.location.reload();
          return 10;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return intervalId;
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Met à jour le tableau showPopups pour montrer le popup pour cet index
        setShowPopups((prev) => {
          const newPopups = [...prev];
          newPopups[index] = true; // Affiche le popup pour cet index
          return newPopups;
        });

        // Cache le popup après 3 secondes
        setTimeout(() => {
          setShowPopups((prev) => {
            const newPopups = [...prev];
            newPopups[index] = false; // Cache le popup pour cet index
            return newPopups;
          });
        }, 3000);
      })
      .catch((err) => {
        console.error("Erreur lors de la copie :", err);
      });
  };

  const processSubscriptions = (subscriptions) => {
    let allNames = [];
    let allTypes = [];
    let totalPrice = 0;
    let totalQuantity = 0;

    subscriptions.forEach((subscription) => {
      const name = subscription.subscriptionName || "N/A";
      const type = subscription.subscriptionType || "N/A";
      const price = subscription.subscriptionPrice
        ? parseFloat(subscription.subscriptionPrice)
        : 0;
      const quantity = subscription.subscriptionQuantity
        ? parseInt(subscription.subscriptionQuantity) // Correction ici: utilisez subscription.subscriptionQuantity
        : 0;

      allNames.push(name);
      allTypes.push(type);
      totalPrice += price;
      totalQuantity += quantity;
    });

    const result = [
      allNames.join(" - "),
      allTypes.join(" - "),
      totalPrice,
      totalQuantity,
    ];
    setAllInfos(result);
  };

  useEffect(() => {
    if (order && order.length > 0) {
      processSubscriptions(order);
      setShowPopups(Array(order.length).fill(false)); // Initialisez showPopups à la longueur des commandes
    }
  }, [order]);


  const handleShowTerme = () => {
    setShowTerme(!showTerme)
  }

  const handleShowReqSupport = () => {
    setShowReqSupport(!showReqSupport)
  }

  if (!_idOrder) {
    return (
      <div className="inprogress">
        <img src={download} alt="loading" />
        <p>Paiement incomplet</p>
        <Link to="/" onClick={handleClose}>
          Fermer
        </Link>
      </div>
    );
  }

  return (
    <div className="subscription">
      <NavBar />
      <div className="success-subscription-layout">
        <div className="subscription-content-layout content-success-left">
          <div className="more-infos-content-success">
            <h3>Details Commande</h3>

            <div className="img-success-subscription">
              {order.length === 1 ? (
                <img
                  src={`${config.img_url}/${order[0].productImage}`}
                  alt="img-product"
                />
              ) : (
                <h2>Commandes Multiples</h2>
              )}
            </div>
            <div className="order-options-details-right sub-total">
              <p>Type:</p>
              <p className="order-options-details-text">{allInfos[1] || "Simple"}</p>
            </div>
            <div className="order-options-details-right">
              <p>Prix:</p>
              <p className="order-options-details-text"> {allInfos[2]} FCFA</p>
            </div>
            <div className="order-options-details-right sub-total">
              <p>Quantité:</p>
              <p className="order-options-details-text">{allInfos[3]}</p>
            </div>
            <hr />
            <div className="order-options-details-right sub-total">
              <p className="order-options-details-text" >Total:</p>
              <p className="order-options-details-text" id="total-payment">{allInfos[2]} FCFA</p>
            </div>
            <p className="infos-inclus-price">(Prix Total: Toute Réduction Exclus)</p>
            <div className="validate-subscription-btn-close">
              <Link to="/" onClick={handleClose} id="btn-close">
                Fermer
              </Link>
            </div>
          </div>

          {order.length > 0 && (
            <div className="client-infos-success-payment">
              <h4>Informations Client</h4>
              <div className="subscription-options-details">
                <p>Nom:</p>
                <h5>
                  {order[0]?.userFirstName} {order[0]?.userLastName}
                </h5>
              </div>
              <div className="subscription-options-details">
                <p>Email:</p>
                <h5>{order[0]?.userEmail}</h5>
              </div>
              <div className="subscription-options-details">
                <p>Téléphone:</p>
                <h5>{order[0]?.userNumberPhone}</h5>
              </div>
              <div className="subscription-options-details">
                <p>Pays:</p>
                <h5>{order[0]?.userCountry}</h5>
              </div>
            </div>
          )}
        </div>

        <div className=" content-success-right">
          <div className="order-validate">
            <img src={iconSuccess} alt="validate" />
            <div>
              <h2>Succès</h2>
              {_idOrder.includes("ReNew") || _idOrder.includes("LTR") ? (
                <p>Votre Renouvellement a été validé avec succès !!!</p>
              ) : (
                <p>Votre abonnement a été validé avec succès !!!</p>
              )}
            </div>
          </div>
          <div className=" details-validate-order ">
            <div className="details-validate-order-content">
              <div className="request-support-content">
                <img src={cartIcon} alt="cart" />
                <h4> Les détails de Votre Commande se trouvent ci-dessous.</h4>
              </div>

              <div className="order-infos">
                <div className="content-infos-success-payment">
                  {order.map((sub, index) => (
                    <div className="content-infos-success-payment-item" key={index}>
                      <div className="order-success-details-product">
                        <img
                          src={`${config.img_url}/${sub.productImage}`}
                          alt="img-product"
                        />
                        <p className="order-options-details-text"> {sub.subscriptionPrice} FCFA</p>
                      </div>
                      {sub && subscriptionValidate && viewOrderInformation ? (
                        (sub.CategoryName.includes("Cartes Cadeaux") || sub.CategoryName === "Gaming") ? (
                          sub.subscriptionKey2.map((key, keyIndex) => (
                            <div key={keyIndex} className="order-information">
                              {key === "Si votre commande n’apparaît pas ici, veuillez contacter notre support client via WhatsApp en fournissant votre numéro de commande."
                                ? (<h5>Si votre commande n’apparaît pas ici, veuillez contacter notre support client via <a href="https://wa.me/message/ZXA2SQX2AWUSB1" target="_blank" rel="noopener noreferrer">WhatsApp</a> en fournissant votre numéro de commande. </h5>)

                                : (
                                  <>
                                    <h5>{`${key}`}</h5>
                                    <div
                                      className="copy-subKey"
                                      onClick={() => copyToClipboard(key, keyIndex)}
                                    >
                                      <h4>Copier</h4>
                                      <FontAwesomeIcon
                                        icon={faCopy}
                                        className="copy-icon"
                                      />
                                    </div>
                                    {showPopups[keyIndex] && (
                                      <div className="copy-popup">
                                        Copié dans le presse-papiers !
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          ))
                        ) : (
                          <div className="order-information">
                            {sub.subscriptionKey === "Si votre commande n’apparaît pas ici, veuillez contacter notre support client via WhatsApp en fournissant votre numéro de commande."
                              ? (<h5>Si votre commande n’apparaît pas ici, veuillez contacter notre support client via <a href="https://wa.me/message/ZXA2SQX2AWUSB1" target="_blank" rel="noopener noreferrer">WhatsApp</a> en fournissant votre numéro de commande. </h5>)
                              :
                              (<>
                                <h5>{`${sub.subscriptionKey}`}</h5>
                                <div
                                  className="copy-subKey"
                                  onClick={() => copyToClipboard(sub.subscriptionKey, index)}
                                >
                                  <h4>Copier</h4>
                                  <FontAwesomeIcon icon={faCopy} className="copy-icon" />
                                </div>
                                {showPopups[index] && (
                                  <div className="copy-popup">
                                    Copié dans le presse-papiers !
                                  </div>
                                )}
                              </>
                              )
                            }
                          </div>
                        )
                      ) : (
                        <div className="order-information view-order-information">
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="icon-infos-validate-sub"
                          />
                          <div className="content-infos-noView-infos">
                            <h5>Informations de votre commande</h5>
                            <p>
                              Ici vous trouverez les identifiants de connexion au compte
                            </p>
                          </div>
                          <img
                            src={arrowRight}
                            alt="more-infos"
                            onClick={OrderInfos}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="details-content-success order-details">
                  <div className="order-options-details">
                    <img src={iconDetails} alt="details-icon" />
                    <strong>Détails du Paiement</strong>
                  </div>
                  <div className="order-details-content">
                    {showPopups[order.length + 2] && (
                      <div className="copy-popup">
                        Numéro de commande Copié!
                      </div>
                    )}
                    <div className="order-options-details">
                      <p> N°Commande:</p>
                      <p className="order-options-details-text" > {_idOrder}</p>
                      <div
                        className="copy-subKey"
                        id="copy-order"
                        onClick={() => copyToClipboard(_idOrder, order.length + 2)}
                      >
                        <h4>Copier</h4>
                        <FontAwesomeIcon icon={faCopy} className="copy-icon" />

                      </div>
                    </div>


                    <div className="order-options-details">
                      <p> Status de paiement:</p>
                      <p className="order-options-details-text"> ✅ Success</p>
                    </div>

                    <div className="order-options-details">
                      <p> Date: </p>
                      <p className="order-options-details-text"> {extraireDateHeure(order[0]?.createdAt)} </p>
                    </div>

                    <div className="order-options-details">
                      <p>Paiement par : </p>
                      <p className="order-options-details-text"> {order[0]?.subscriptionPaymentMethod || "Non définie"}  </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="request-support">
              <div className="request-support-rs">
                <div className={`request-support-title  ${showTerme ? "active-btn-req-support" : ""}`} onClick={handleShowTerme} >
                  <img src={howToUse} alt="icon" />
                  <h5>Comment ça marche </h5>
                </div>
                <div className={`request-support-title  ${showReqSupport ? "active-btn-req-support" : ""}`} onClick={handleShowReqSupport}>
                  <img src={ReqSupport} alt="icon" />
                  <h5>Demande de Support</h5>
                </div>
              </div>

              {
                showTerme && (
                  <p className="order-term">  <strong> Terme: </strong>  {order[0].policy} </p>
                )
              }
              {
                showReqSupport &&
                (<div className="request-support-rs">
                  <a className="request-support-whatsapp" href="https://wa.me/message/ZXA2SQX2AWUSB1" target="_blank" rel="noopener noreferrer">
                    <img src={whatsAppIcon} alt="icon" />
                    <h5>WhatsApp</h5>
                  </a>
                  <a href="https://t.me/senepremium1" target="_blank" className="request-support-telegram" rel="noopener noreferrer">
                    <img src={telegramIcon} alt="icon" />
                    <h5>Telegram</h5>
                  </a>
                </div>)}
            </div>

            <p className="send-infos-email order-options-details-text"> ✅ Les Informations de la Commande vous seront asussi envoyé par email !!!</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ValidateSubscription;
