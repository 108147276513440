import React, { useContext, useState } from 'react';
import "../../Assets/Styles/Home.css";
import { DataContext } from '../../Services/DataContext';
import CustomListLayout from '../../Layouts/CustomListLayout';
import Footer from "../../Layouts/Footer";
import listElementsScrolling from '../Home/ListElementsScrolling';
import iconMusic from "../../Assets/Imgs/icons/icon-music2.svg";
import iconGaming from "../../Assets/Imgs/icons/icons8-game-controller-50.png"
import iconCArteCadeau from "../../Assets/Imgs/icons/icon-carte-cadeau.svg";
import iconEducation from "../../Assets/Imgs/icons/icon-education2.svg";
import iconSecurity from "../../Assets/Imgs/icons/icon-security2.svg";
import iconLogiciel from "../../Assets/Imgs/icons/icon-app2.svg";
import iconVideo from "../../Assets/Imgs/icons/icon-tv2.svg";
import iconDesign from "../../Assets/Imgs/icons/icon-design.svg";
import search from "../../Assets/Imgs/icons/search-svgrepo-com (1).svg";
import moreFrequently from "../../Assets/Imgs/icons/mostFrequently.svg"
import CategoriesScroll from './CategoriesScroll';
import allProducts from "../../Assets/Imgs/icons/allProducts.svg"
import imgBannerProduct from "../../Assets/Imgs/image banner product.png"
import searchLine from "../../Assets/Imgs/icons/search-line.svg"
import { Link } from "react-router-dom"
import CountDownTimer from '../../Layouts/CountDownTimer';

function BannerProduct() {
    const { products, video, music, services, securite, design, carteCadeauEtJeux, gaming, education, logiciel } = useContext(DataContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeSection, setActiveSection] = useState('');

    // window.scrollTo(0, 0);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const sections = document.querySelectorAll('.categories-product-elements');
    //         let currentSection = '';

    //         sections.forEach(section => {
    //             const sectionTop = section.offsetTop;
    //             if (window.pageYOffset >= sectionTop - 50) {
    //                 currentSection = section.getAttribute('id');
    //             }
    //         });

    //         setActiveSection(currentSection);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    // const filteredProducts = products.filter(product => {
    //     return product.productName.toLowerCase().includes(searchTerm.toLowerCase()) || product.productType.toLowerCase().includes(searchTerm.toLowerCase());
    // });

    const filteredProducts = products.filter(product => {
        const searchTermWords = searchTerm.toLowerCase().split(" ");
        const productName = product.productName.toLowerCase();
        const productType = product.productType.toLowerCase();

        return searchTermWords.every(word =>
            productName.includes(word) || productType.includes(word)
        );
    });

    // Fonction pour mélanger un tableau (Fisher-Yates)
    const shuffleArray = (array) => {
        let shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };
    let mixProducts = shuffleArray(products)
    mixProducts = mixProducts.filter((product) => !product.productName.toLowerCase().includes("iptv"))
    // console.log(video);

    //Afficher ces produits en premier avec les videos
    const priorityProducts = [
        'NETFLIX 1 écran 1Mois',
        'Disney Plus 1Mois',
        'IPTV premium 1Mois',
        'Prime Video 1Mois',
        'Crunchyroll 1Mois',
    ];

    const priority = video.filter(product => priorityProducts.includes(product.productName));
    const others = video.filter(product => !priorityProducts.includes(product.productName));
    const shuffledOthers = shuffleArray(others);
    const newArrayVideo = [...priority, ...shuffledOthers];

    const topMusic = [
        "Spotify 3Mois",
        "Deezer 3Mois",
        "Spotify 1Mois",
        "Apple Music 3Mois",
    ];

    //faire la mm chose avec music
    const priorityMusic = music.filter(product => topMusic.includes(product.productName));
    const othersMusic = music.filter(product => !topMusic.includes(product.productName));
    // const shuffledOthers = shuffleArray(others);
    const newArrayMusic = ([...priorityMusic, ...othersMusic]);



    const topCarteCadeaux = [
        'Apple iTunes 10€ (France)',
        'PlayStation Network (PSN) 10€',
        'Fortnite 1000 v-bucks',
        'iTunes 25€',
        'Roblox 10€',
        'iTunes USA 10$',
        'iTunes 5€',
        'PSN 15€'
    ];

    //faire la mm chose avec CarteCadeaux
    const priorityCarteCadeaux = carteCadeauEtJeux.filter(product => topCarteCadeaux.includes(product.productName));
    const othersCarteCadeaux = carteCadeauEtJeux.filter(product => !topCarteCadeaux.includes(product.productName));
    // const shuffledOthers = shuffleArray(others);
    const newArrayCarteCadeaux = ([...priorityCarteCadeaux, ...othersCarteCadeaux]);

    const productsInPromo = products.filter(product => product.promo?.InPromo === true)
    // console.log(productsInPromo);

    const topPromo = [
        "Apple iTunes 10€ (France)",
        "PlayStation Network (PSN) 10€",
        "Pack Solo Netflix & Prime, 1 écran",
        "Pack Disney+ x Prime Video x VPN Premium 1Mois"
    ];

    //faire la mm chose avec music
    const priorityPrmo = productsInPromo.filter(product => topPromo.includes(product.productName));
    const othersProductsInPromo = productsInPromo.filter(product => !topPromo.includes(product.productName));
    // const shuffledOthers = shuffleArray(others);
    const newArrayProductsInPromo = ([...priorityPrmo, ...othersProductsInPromo]);



    return (
        <div className="banner-product">

            <div className='layout-banner-product' id='content-product'>
                <div className='layout-banner-left' id='content-left-products'>
                    <div className='intro-banner-product'>
                        <span> Abonnement,</span>
                        <span id='gift-card'>Cartes Cadeaux</span>
                        <span>, & Plus</span>
                    </div>
                    <p className='description-banner-product'> Découvrez une variété passionnante d'offres avec nos abonnements, cartes-cadeaux, jeux et plus encore. Que vous recherchiez le cadeau parfait</p>
                    <div className='search-subscription-product'>
                        <input
                            type='text'
                            placeholder='Rechercher un abonnement'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className='content-icon-search'>
                            <img src={search} alt='search icon' className='icon-search' />
                        </div>
                        {
                            searchTerm &&
                            (<div className="popup-overlay" onClick={() => setSearchTerm('')}>
                                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                                    <Link to="https://15b2dvgqxia.typeform.com/to/jfFN9w2d" className="active" >Suggérer un produit inexistant sur Senpremium</Link>
                                    <CustomListLayout listItems={filteredProducts} typeName="Résultats" iconType={searchLine} />
                                </div>
                            </div>)
                        }
                    </div>
                </div>

                <div className='layout-banner-right'>
                    <img src={imgBannerProduct} alt="" />
                </div>
            </div>

            <div className='categories-nav'>
                <CategoriesScroll setActiveSection={setActiveSection} />
            </div>
            {/* <div className="count-down-timer-layout  count-down-timer-layout-product">
                <CountDownTimer />
                <div className='frequently-product' >
                    <h2 className='title-section-promo'>Offre Flash</h2>
                    <CustomListLayout
                        listItems={newArrayProductsInPromo}
                        typeName="Produits en Promo"
                        typeBackGround="backGroundOrange"
                        iconType={moreFrequently}
                        inPromo = {true} 
                    />
                </div>
            </div> */}
            <div className='categories-product'>

                <div className='frequently-product'>
                    <CustomListLayout
                        listItems={shuffleArray(listElementsScrolling)}
                        typeName="Les plus Populaires"
                        // typeBackGround="lineBackGroundGray"
                        iconType={moreFrequently}
                    />
                </div>
                <div id='products' className={`categories-product-elements ${activeSection === 'products' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(mixProducts)}
                        typeName="Tous les produits"
                        typeBackGround="backGroundOrange"
                        iconType={allProducts}
                    />
                </div>

                <div id='video' className={`categories-product-elements ${activeSection === 'video' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={newArrayVideo}
                        typeName="Streaming Video"
                        // typeBackGround="lineBackGroundGray"
                        iconType={iconVideo}
                    />
                </div>

                <div id='cartes' className={`categories-product-elements ${activeSection === 'cartes' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={newArrayCarteCadeaux}
                        typeName="Cartes Cadeaux"
                        typeBackGround="backGroundOrange"
                        iconType={iconCArteCadeau}
                    />
                </div>

                <div id='gaming' className={`categories-product-elements ${activeSection === 'gaming' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(gaming)}
                        typeName="Gaming"
                        // lineBackGroundGray="lineBackGroundGray"
                        iconType={iconGaming}
                    />
                </div>

                <div id='music' className={`categories-product-elements ${activeSection === 'music' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={newArrayMusic}
                        typeName="Musique"
                        typeBackGround="backGroundOrange"
                        iconType={iconMusic}
                    />
                </div>

                <div id='design' className={`categories-product-elements ${activeSection === 'design' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(design)}
                        typeName="Design"
                        // lineBackGroundGray="lineBackGroundGray"
                        iconType={iconDesign}
                    />
                </div>

                <div id='security' className={`categories-product-elements ${activeSection === 'security' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(securite)}
                        typeName="Securite"
                        typeBackGround="backGroundOrange"
                        iconType={iconSecurity}
                    />
                </div>

                <div id='services' className={`categories-product-elements ${activeSection === 'services' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(services)}
                        typeName="Services"
                        // lineBackGroundGray="lineBackGroundGray"
                        iconType={iconLogiciel}
                    />
                </div>

                <div id='education' className={`categories-product-elements ${activeSection === 'education' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(education)}
                        typeName="Education"
                        typeBackGround="backGroundOrange"
                        iconType={iconEducation}
                    />
                </div>

                <div id='logiciel' className={`categories-product-elements ${activeSection === 'logiciel' ? 'active' : ''}`}>
                    <CustomListLayout
                        listItems={shuffleArray(logiciel)}
                        typeName="Logiciel et Clé d'activation"
                        // lineBackGroundGray="lineBackGroundGray"
                        iconType={iconLogiciel}
                    />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default BannerProduct;
