import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import imgBanner from "../../Assets/Imgs/home-banner.png";
import imgBanner2 from "../../Assets/Imgs/home-banner2.png";
import imgBanner3 from "../../Assets/Imgs/home-banner3.png";


function GeneralBanner() {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [imgBanner2, imgBanner3]; // Liste des images à alterner

    useEffect(() => {
        const interval = setInterval(() => {
            currentImage === 0 ? setCurrentImage(1) : setCurrentImage(0)
        }, 3000); // Changer d'image toutes les 2 secondes

        return () => clearInterval(interval); // Nettoie l'intervalle lorsque le composant est démonté
    }, [currentImage]);
    return (
        <div className='banner-product'>
            <div className='layout-banner-product'>

                <div className='layout-banner-left'>
                    <div className='layout-banner-left-description' >
                        <p className='description-banner'> + 100 SERVICES DISPONIBLES--------------</p>

                        <div className='intro-banner-product'>
                            <span> Abonnement,</span>
                            <span id='gift-card'>Cartes Cadeaux</span>
                            <span>, Jeux, Bien Encore Plus</span>
                        </div>

                        <Link to="/products" className='see-all-products'> Voir tous les abonnements</Link>

                    </div>

                    <img src={images[currentImage]} alt="banner-dynamic" className='img-banner-home slide-in' />

                </div>

                <div className='layout-banner-right-home'>
                    <img src={imgBanner} alt="banner" />
                </div>

                {/* <div className='scroll-banner-home'>
</div> */}
            </div>
        </div>
    )
}

export default GeneralBanner