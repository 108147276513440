import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import fastIcon from '../../Assets/Imgs/icons/flash-icon.svg';
import emailIcon from '../../Assets/Imgs/icons/mail-resend-icon.svg';
import secureIcon from '../../Assets/Imgs/icons/secure-payment.svg';

const DescriptionSubs = () => {
    const data = [
        { img: emailIcon, text: <><strong>Livraison instantanée</strong> par e-mail et WhatsApp</> },
        { img: secureIcon, text: <>Paiement <strong>sécurisé</strong> et <strong>fiable</strong></> },
        { img: fastIcon, text: <>Profitez <strong>immédiatement</strong></> }
    ];
    return (
        <div className='description-subs'>
            <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{
                    delay: 3000, // 3 seconds
                    disableOnInteraction: false
                }}
                pagination={{ clickable: true }}
                breakpoints={{
                    768: { // Desktop
                        slidesPerView: 3,
                        spaceBetween: 30
                    }
                }}
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className='description-subs-content'>
                            <img src={item.img} alt="description-subs-img" />
                            <p>{item.text}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default DescriptionSubs;
