// ListElementsScrolling.js
import iconMusic from "../../Assets/Imgs/icons/icons8-musical-note-100.png"
import iconVideo from "../../Assets/Imgs/icons/icon-tv.svg"
import iconGame from "../../Assets/Imgs/icons/icons8-game-100.png"
import iconEducation from "../../Assets/Imgs/icons/icons8-education-100.png"
import iconDesign from "../../Assets/Imgs/icons/icons8-design-100.png"
// import iconApp from "../../Assets/Imgs/icons/icon-app.svg"



const listElementsScrolling = [
    {
        _id : "662c4c3c7c6f78fa8e417300" ,
        productCategory : "Streaming Video" ,
        productType :"PREMIUM 4K UHD" ,
        productName : "NETFLIX 1 écran 1Mois",
        categoryImage : iconVideo ,
        productImage : "img-netflix-1.jpg",
        productDescription : "Abonnement Netflix 4K UHD pour un appareil : \r\n Profitez d'un visionnage premium en ultra-haute définition sur un seul appareil. Accédez à un catalogue complet de films et séries, avec la possibilité de télécharger pour un visionnage hors connexion. L'abonnement est accessible sur tous les appareils compatibles, garantissant une expérience utilisateur optimale avec support complet. Vivez le meilleur de Netflix, avec une qualité d'image exceptionnelle et sans restrictions régionales.",
        productPrice : 2500,
        productPrice2 : 3600,
        id_style : "netflix",
    },
    {
        _id : "662c4e897c6f78fa8e417375" ,
        productCategory : "Streaming Video" ,
        productType :"PREMIUM" ,
        productName : "Prime Video 1Mois",
        categoryImage : iconVideo ,
        productImage : "img-prime-video.jpg",
        productDescription : "Prime Video offre un accès illimité à un large éventail de films, séries, et Amazon Originals. Regardez en ligne ou téléchargez pour voir hors ligne sur tout appareil. Abonnez-vous pour des divertissements captivants et variés à la demande."        ,
        productPrice : 2500,
        id_style : "prime-video",
    },
    {
        _id : "662c50fd7c6f78fa8e417404" ,
        productCategory : "Cartes Cadeaux et Jeux Video" ,
        productType :"iTunes France" ,
        productName : "Apple iTunes 10€ (France)",
        categoryImage : iconMusic ,
        productImage : "img-apple-itunes-.jpg",
        productDescription : "La carte iTunes Apple est votre clé pour un univers de divertissement. Utilisez-la pour acheter des applications, de la musique, des films, des émissions de télévision, des livres, ou encore pour augmenter votre espace de stockage iCloud. Valide sur l’iTunes Store, l'App Store, et pour les abonnements comme Apple Music et Apple TV+, cette carte offre une flexibilité totale pour profiter de vos contenus préférés sur tous vos appareils Apple. Parfait pour gâter vos proches ou vous faire plaisir."        ,
        productPrice : 8000,
        id_style : "apple-music",
    },
    {
        _id : "662c51c77c6f78fa8e417415" ,
        productCategory : "Cartes Cadeaux et Jeux Video" ,
        productType :"PSN France" ,
        productName : "PlayStation Network (PSN) 10€",
        categoryImage : iconGame ,
        productImage : "Carte-psn-10-euro.jpg",
        productDescription : "Achetez une carte PSN pour enrichir votre expérience sur PlayStation. Utilisez-la pour acquérir des jeux téléchargeables, des extensions, des films, des émissions de télévision, et d'autres contenus multimédias. Tout ce que vous achetez peut être joué ou visionné sur votre console PlayStation, vous offrant un accès instantané à une vaste bibliothèque de divertissements numériques. Profitez au maximum de votre PlayStation avec la flexibilité et la commodité qu'offre la carte PSN."        ,
        productPrice : 7500,
        id_style : "playStation-network",
    },
    
    {
        _id : "664dc1622cdb3ec78e44350c" ,
        productCategory : "Streaming Video" ,
        productType :"PREMIUM" ,
        productName : "IPTV premium 1Mois",
        categoryImage : iconVideo,
        productImage : "iptv.jpg",
        productDescription : "Le service IPTV Premium offre un accès exceptionnel à un contenu télévisuel riche et diversifié avec plus de 19 000 chaînes et 50 000 séries du monde entier. Idéal pour les amateurs de télévision et de séries, ce forfait inclut des chaînes locales, internationales, et des contenus exclusifs en qualité HD et 4K, selon la disponibilité. Profitez de vos programmes favoris, de sports en direct, de films, de documentaires, et bien plus encore, tout cela sur votre appareil de prédilection. Cette offre IPTV transforme votre expérience de visionnement en vous donnant la liberté de choisir ce que vous voulez regarder, quand vous le voulez."        ,
        productPrice : 3000 ,
        id_style : "apple-music",
    },

    {
        _id : "662e3353dc62b51a3d034b10" ,
        productCategory : "Cartes Cadeaux et Jeux Video" ,
        productType :"1000 Vbucks" ,
        productName : "Fortnite 1000 V-bucks (Global)",
        categoryImage : iconGame,
        productImage : "fortenit.jpg",
        productDescription : "Fortnite 1000 V-Bucks :\r\n\r\nAchetez 1000 V-Bucks pour Fortnite, la monnaie virtuelle utilisée dans le jeu pour acquérir des skins, des emotes, des passes de combat et d'autres contenus cosmétiques. Avec ces V-Bucks, personnalisez votre expérience de jeu en ajoutant du style à votre personnage ou en obtenant les derniers items pour vous démarquer sur le champ de bataille. Les V-Bucks peuvent être utilisés dans tous les modes de jeu, y compris Battle Royale, Sauver le Monde, et Créatif. Offrez-vous ou offrez à un ami la possibilité de choisir parmi une multitude d'options de personnalisation pour améliorer votre expérience Fortnite."        ,
        productPrice : 8000,
        id_style : "fortinite",
    },


    {   
        _id: "662d81547c6f78fa8e4175ca", 
        productCategory: "Streaming Video",
        productName: "NETFLIX Pack Family 5 écrans 1Mois",
        productType: "PREMIUM 4K UHD",
        productImage: "netflix-pack-family.jpg",
        productDescription: "Le Pack Family de Netflix permet à jusqu'à 5 utilisateurs de profiter simultanément d'un accès illimité à un vaste catalogue de films, séries, documentaires, et émissions pour enfants. Ce plan est parfait pour les familles ou les groupes d'amis, offrant la possibilité de créer jusqu'à 5 profils individuels pour personnaliser les recommandations et garder les listes de visionnage séparées. Chaque utilisateur peut regarder sur son appareil préféré, en haute définition ou en 4K selon l'option choisie, garantissant une expérience de visionnage optimale pour tous.",
        productPrice: 9000,
        id_style : "netflix",
        categoryImage : iconVideo,



    },
    
    {
        _id : "662d832cdc62b51a3d034a41" ,
        productCategory : "Streaming Video" ,
        productType :"Mega Fan" ,
        productName : "Crunchyroll 1Mois",
        categoryImage : iconVideo,
        productImage : "crunchyroll.jpg",
        productDescription : "\n\nLe forfait Mega Fan de Crunchyroll offre un streaming sans publicités, la possibilité de visionner sur 2 appareils à la fois, et des épisodes disponibles une heure après le Japon. Profitez également de téléchargements hors ligne, d'accès exclusif à des événements Crunchyroll, et de réductions sur la boutique. Parfait pour les passionnés d'anime voulant une expérience complète."        ,
        productPrice : 3000,
        id_style : "crunchyroll",
    },
    {
        _id : "662c5c767c6f78fa8e4174c3" ,
        productCategory : "Music" ,
        productType :"PREMIUM" ,
        productName : "Deezer 1Mois",
        categoryImage : iconMusic,
        productImage : "img-deezer.jpg",
        productDescription : "Deezer Premium offre une expérience d'écoute musicale sans interruptions publicitaires. Abonnez-vous pour accéder à un catalogue riche de millions de chansons, playlists, et podcasts en streaming illimité. Profitez de la musique avec une qualité audio supérieure, téléchargez vos titres favoris pour les écouter hors connexion, et écoutez votre contenu sur tous vos appareils. Deezer Premium vous permet également de passer des chansons à volonté, pour une expérience totalement personnalisée." ,
        productPrice : 2000,
        id_style : "fortinite",
    },
    {
        productCategory: "Music",
        productDescription: "Spotify Premium offre une écoute sans pubs, avec des téléchargements pour une écoute hors ligne et une qualité audio supérieure. Profitez de fonctionnalités avancées comme des sauts illimités, des playlists exclusives, et des nouveautés en avant-première. Transformez votre expérience musicale et podcast avec Premium.",
        productImage: "img-spotify.jpg",
        productName: "Spotify Premium 3Mois",
        productPrice: 5000,
        productType: "Individuel",
        updatedAt: "2024-06-01T21:30:58.763Z",
        _id: "662c4f5f7c6f78fa8e4173b3",
        categoryImage : iconMusic,
        id_style : "spotify",

    },
    {
        productCategory: "Cartes Cadeaux et Jeux Video",
        productDescription: "\r\nCarte Roblox 10€:\r\n\r\nLa carte Roblox est un moyen idéal pour charger des Robux, la monnaie virtuelle du jeu Roblox, sur votre compte. Utilisez vos Robux pour acheter des améliorations, des avatars personnalisés, des accessoires, des compétences spéciales, ou même des animaux de compagnie dans le jeu. En plus des Robux, cette carte peut également débloquer des abonnements au Roblox Premium, offrant des avantages supplémentaires comme un accès à des fonctionnalités exclusives, des Robux mensuels gratuits, et une meilleure expérience dans le trading d'items. Parfait pour les joueurs cherchant à enrichir leur expérience de jeu ou à offrir un cadeau spécial à un fan de Roblox.",
        productImage: "roblox.webp",
        productName: "Cartes Roblox 10€",
        productPrice: 8900,
        productType: "ROBLOX EURO",
        updatedAt: "2024-04-28T12:58:13.667Z",
        _id: "662e46a7dc62b51a3d034b2e",
        categoryImage : iconGame ,
        id_style : "fortinite",


    },
    {
        productCategory: "Streaming Video",
        productDescription: "🌟Pack Stream Solo🌟 : Un seul abonnement qui vous donne l'accès à 📺Netflix et Prime Vidéo📽️\r\npour 1⃣ mois sur un seul écran. Découvrez un univers de divertissement sans fin avec des milliers de films🎞️,\r\nséries🎭, documentaires et bien plus encore. Que vous soyez fan de drames captivants , de comédies hilarantes,\r\nde documentaires instructifs🎓 ou de thrillers palpitants, ce pack vous ouvre les portes de deux des plus grandes plateformes de streaming.\r\nNote : ce pack est valable pour un seul appareil 🖥️.",
        productImage: "netflix-prime-.jpg",
        productName: "Pack Solo Netflix & Prime, 1 écran",
        productPrice: 4000,
        productPrice2: 5200,
        productType: "Premium",
        categoryImage : iconVideo,
        _id: "664b85282cdb3ec78e442918",
        id_style : "apple-music",

    },
    {
        _id : "665f4e6b6425f7322d8b3a19" ,
        productCategory : "Cartes Cadeaux et Jeux Video" ,
        productType :"iTunes France" ,
        productName : "Apple iTunes 15€ (France)",
        categoryImage : iconMusic,
        productImage : "img-apple-itunes-.jpg",
        productDescription : "La carte iTunes Apple est votre clé pour un univers de divertissement. Utilisez-la pour acheter des applications, de la musique, des films, des émissions de télévision, des livres, ou encore pour augmenter votre espace de stockage iCloud. Valide sur l’iTunes Store, l'App Store, et pour les abonnements comme Apple Music et Apple TV+, cette carte offre une flexibilité totale pour profiter de vos contenus préférés sur tous vos appareils Apple. Parfait pour gâter vos proches ou vous faire plaisir."        ,
        productPrice : 11000 ,
        id_style : "apple-music",
    },
    {
        productCategory: "Streaming Video",
        productDescription: "Le package Disney+ avec VPN offert est parfait pour accéder à Disney, Pixar, Marvel, Star Wars et National Geographic sans restrictions géographiques. Il inclut un abonnement Disney+ et un service VPN premium pour contourner les blocages régionaux et améliorer la sécurité en ligne. Profitez de vos films et séries en 4K sur 1 appareil, partout dans le monde. Idéal pour les familles et les fans de Disney souhaitant une expérience de streaming optimale tout en protégeant leurs données.",
        productImage: "disney-plus-.jpg",
        productName: "Disney Plus 1Mois",
        productPrice: 3000,
        productType: "PREMIUM + VPN Gratuit",
        categoryImage : iconVideo,
        _id: "662d8240dc62b51a3d034a39",
        id_style : "playStation-network",

    }
    ,
    
    {
        _id : "662c50d67c6f78fa8e4173fc" ,
        productCategory : "Cartes Cadeaux et Jeux Video" ,
        productType :"iTunes France" ,
        productName : "Apple iTunes 5€ (France)",
        categoryImage : iconMusic,
        productImage : "img-apple-itunes-.jpg",
        productDescription : "La carte iTunes Apple est votre clé pour un univers de divertissement. Utilisez-la pour acheter des applications, de la musique, des films, des émissions de télévision, des livres, ou encore pour augmenter votre espace de stockage iCloud. Valide sur l’iTunes Store, l'App Store, et pour les abonnements comme Apple Music et Apple TV+, cette carte offre une flexibilité totale pour profiter de vos contenus préférés sur tous vos appareils Apple. Parfait pour gâter vos proches ou vous faire plaisir."        ,
        productPrice : 4800 ,
        id_style : "apple-music",
    },
    {
        _id : "662c4c9c7c6f78fa8e417310" ,
        productCategory : "Streaming Video" ,
        productType :"PREMIUM 4K UHD" ,
        productName : "NETFLIX 1 écran 3Mois",
        categoryImage : iconVideo ,
        productImage : "img-netflix-1.jpg",
        productDescription : "Abonnement Netflix 4K UHD pour un appareil : \r\n Profitez d'un visionnage premium en ultra-haute définition sur un seul appareil. Accédez à un catalogue complet de films et séries, avec la possibilité de télécharger pour un visionnage hors connexion. L'abonnement est accessible sur tous les appareils compatibles, garantissant une expérience utilisateur optimale avec support complet. Vivez le meilleur de Netflix, avec une qualité d'image exceptionnelle et sans restrictions régionales.",
        productPrice : 7000,
        productPrice2 : 10200,
        id_style : "netflix",
    },
    // {
    //     _id : "662d8940dc62b51a3d034a7e" ,
    //     productCategory : "Streaming Video" ,
    //     productType :"PREMIUM" ,
    //     productName : "IPTV premium 3Mois",
    //     categoryImage : iconVideo,
    //     productImage : "iptv.jpg",
    //     productDescription : "Le service IPTV Premium offre un accès exceptionnel à un contenu télévisuel riche et diversifié avec plus de 19 000 chaînes et 50 000 séries du monde entier. Idéal pour les amateurs de télévision et de séries, ce forfait inclut des chaînes locales, internationales, et des contenus exclusifs en qualité HD et 4K, selon la disponibilité. Profitez de vos programmes favoris, de sports en direct, de films, de documentaires, et bien plus encore, tout cela sur votre appareil de prédilection. Cette offre IPTV transforme votre expérience de visionnement en vous donnant la liberté de choisir ce que vous voulez regarder, quand vous le voulez."        ,
    //     productPrice : 8000 ,
    //     id_style : "apple-music",
    // },
    
    // {
    //     _id : "6678c438ffee53687b48ec3b" ,
    //     productCategory : "Cartes Cadeaux et Jeux Video" ,
    //     productType :"PSN France" ,
    //     productName : "PlayStation Network (PSN) 15€",
    //     categoryImage : iconGame ,
    //     productImage : "Carte-psn-10-euro.jpg",
    //     productDescription : "Achetez une carte PSN pour enrichir votre expérience sur PlayStation. Utilisez-la pour acquérir des jeux téléchargeables, des extensions, des films, des émissions de télévision, et d'autres contenus multimédias. Tout ce que vous achetez peut être joué ou visionné sur votre console PlayStation, vous offrant un accès instantané à une vaste bibliothèque de divertissements numériques. Profitez au maximum de votre PlayStation avec la flexibilité et la commodité qu'offre la carte PSN."        ,
    //     productPrice : 11000,
    //     id_style : "playStation-network",
    // },
    {
        _id : "662c540b7c6f78fa8e417462" ,
        productCategory : "Education" ,
        productType :"GPT 4 -DALL E" ,
        productName : "ChatGPT Co-abonnement",
        categoryImage : iconEducation ,
        productImage : "Chatgpt-plus.jpg",
        productDescription : "ChatGPT-4 Plus avec DALL-E réunit l'avancée de l'intelligence artificielle textuelle et visuelle en une seule plateforme. Ce produit permet des interactions textuelles riches et complexes avec ChatGPT-4, capable de comprendre et de générer des réponses dans une multitude de contextes et de langues. En ajoutant DALL-E, la plateforme offre également la capacité de créer des images, des art visuels, et des modifications graphiques précises à partir de descriptions textuelles. Idéal pour les professionnels créatifs, les éducateurs, et toute personne intéressée par l'exploration des possibilités de l'IA en matière de création de contenu multimédia.",
        productPrice : 8000,
        id_style : "spotify",
    },
    {
        _id : "662c59ee7c6f78fa8e417489" ,
        productCategory : "Design" ,
        productType :"Pro" ,
        productName : "Canva pro 3Mois",
        categoryImage : iconDesign ,
        productImage : "canva-.jpg",
        productDescription : "Canva Pro est une plateforme de design graphique qui offre des outils puissants et faciles à utiliser pour créer des visuels professionnels. Avec un abonnement à Canva Pro, vous avez accès à une vaste bibliothèque de modèles premium, de photos, de vidéos et de graphiques. Les fonctionnalités avancées incluent le redimensionnement automatique des designs, l'exportation de fond transparent, et la possibilité de programmer des publications sur les réseaux sociaux directement depuis Canva. Canva Pro est idéal pour les entrepreneurs, les marketeurs, et tous ceux qui cherchent à produire du contenu visuel attrayant et efficace rapidement et sans compétences techniques avancées en design.",
        productPrice : 7000,
        id_style : "prime-video",
    }


    
]
export default listElementsScrolling 