import React from 'react';
import { Link } from 'react-router-dom';
import config from "../Services/config.js";
import "../Assets/Styles/MainLayout.css";
import "../Assets/Styles/Home.css";
import "../Assets/Styles/Products.css";
import listElementsScrolling from '../Components/Home/ListElementsScrolling.js';
import flash from "../Assets/Imgs/icons/flash.svg"

function CustomListLayout(props) {
  const { listItems, typeName, typeBackGround, iconType, lineBackGroundGray , inPromo } = props;


  // Fonction pour déterminer si un produit est populaire
  const isPopular = (productName) => {
    return listElementsScrolling.some((item) => item.productName === productName);
  };

  return (
    <div className='custom-list-layout'>
      <div className='list-elements-wrapper'>
        <div className={`list-elements-details ${typeBackGround}`}>
          {
            !inPromo && 
            (
              <div className='categories-product-elements-title'>
            <div className='title-category'>
              <h1>{typeName}</h1>
              <img src={iconType} alt="img-category" />
            </div>
            <hr className={`horizontal-line ${lineBackGroundGray}`} />
            <Link to={`/products/products-category/${typeName}`} className='view-all'> Voir Tous</Link>
          </div>
            )
          }

          <div className='items-subs'>
            {listItems.map((item, index) => (
              <div className='list-elements custom-list-details' key={index}>
                <div className='custom-list-details-card'>
                  {/* Affichage conditionnel du statut */}
                  <div className='card-img'>
                    <Link
                      to={item.productInStock && item.productInStock === "Out of Stock" ? "#" : `/subscription/${item._id}`}
                    >
                      <img src={`${config.img_url}/${item.productImage}`} alt="img" className='img-product-list' />
                    </Link>
                  </div>
                  {(item.promo && item.promo.InPromo) || (item.inPromo) ? (
                    <div className='status-products'>
                      <img className='flash' src={flash} alt="flash" />
                      <p className='text-in-promo'>En Promo</p>
                    </div>
                  ) : isPopular(item.productName) ? (
                    <div className='status-products status-popular'> <p className='text-in-promo text-popular'> Populaire</p> </div>
                  ) : null}

                  <div className='card-details-elements'>
                    <p id='type-sub'>{item.productType}</p>
                    <h4>{item.productName}</h4>
                    <div className='price-product'>
                      {item.promo?.InPromo ?
                        (
                          <div className='custom-price-inPromo' >
                            {/* <span
                              style={{
                                textDecoration: "line-through",
                                textDecorationColor: "red",
                                color : 'gray' ,
                                fontStyle: "italic",
                              }}
                            >
                              {item.productPrice} FCFA
                            </span>
                            <hr className='line-separate' /> */}
                            <span className='price-promo'>{item.promo?.productPricePromo} FCFA</span>
                          </div>
                        )
                        : <span >{item.productPrice} FCFA</span>
                      }
                      {/* Afficher le deuxième prix pour Netflix */}
                      {item.productName.toLowerCase().includes("netflix")
                        && !item.productName.includes("NETFLIX Pack Family")
                        && !item.productName.includes("Netflix Duo")
                        && (
                          <div className='custom-price-inPromo'>
                            <hr className='line-separate' />
                            <span> {`${item?.productPrice2} FCFA`} </span>
                          </div>
                        )}
                    </div>

                    {item.productInStock && item.productInStock === "Out of Stock" ? (
                      <div className='product-rupture'>En Rupture</div>
                    ) : (
                      <div id='btn-subscription'>
                        {item.productCategory.includes("Cartes Cadeaux") ? (
                          <Link to={`/subscription/${item._id}`} className='link-subscription' id='btn-banner'>Acheter</Link>
                        ) : (
                          <Link to={`/subscription/${item._id}`} className='link-subscription' id='btn-banner'>S'abonner</Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomListLayout;
